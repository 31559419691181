<template>
  <div>
    <v-list flat subheader three-line>
      <v-subheader v-if="flashcards == undefined"
        >Tydzień {{ week }}</v-subheader
      >

      <v-skeleton-loader
        v-if="loading"
        type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
      ></v-skeleton-loader>
      <v-list-item-group multiple active-class="">
        <v-list-item
          v-for="task in tasksForMe"
          :key="task.id"
          link
          :to="
            flashcards == undefined
              ? { name: 'Learning', params: { id: task.key } }
              : { name: 'FlashcardsShow', params: { id: task.key } }
          "
        >
          <v-list-item-action>
            <v-checkbox v-if="task.done" input-value="true"></v-checkbox>
            <v-checkbox v-else></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title
              >{{ task.title }}
              <v-chip
                v-if="task.type == 'article'"
                class="ma-2"
                small
                color="orange"
                text-color="white"
              >
                Artykuł
                <v-icon small right>mdi-file-document-outline</v-icon>
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle>{{ task.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  props: ["tasks", "week", "loading", "flashcards", "group"],
  data() {
    return {
      tasksForMe: [],
    };
  },
  computed: {
    doneTasks() {
      return this.$store.state.doneTasks;
    },
  },
  methods: {
    filterTasks(task) {
      if (task.onlyForGroups == undefined) return true;
      else return task.onlyForGroups.includes(this.group);
    },
  },
  watch: {
    tasks: function() {
      this.tasksForMe = this.tasks.filter(this.filterTasks);

      this.doneTasks.forEach((task) => {
        let index = this.tasks.findIndex((x) => x.key === task);
        if (this.tasks[index] != undefined) {
          this.tasks[index].done = true;
        }
      });
    },
  },
};
</script>

<style></style>
