<template>
  <div>
    <v-alert border="top" colored-border type="info" elevation="2">
      <i>Archiwum</i> pozwala na zobaczenie i wykonanie ćwiczeń z poprzednich
      tygodni.
    </v-alert>
    <v-btn v-if="previousBtn" @click="changeWeek(currentWeekNumber - 1)"
      >Poprzedni tydzień</v-btn
    >
    <v-btn v-else disabled>Poprzedni tydzień</v-btn>
    <v-btn
      v-if="nextBtn"
      @click="changeWeek(currentWeekNumber + 1)"
      class="ml-2"
      >Następny tydzień</v-btn
    >
    <v-btn v-else disabled>Następny tydzień</v-btn>
    <TaskList
      :tasks="taskList"
      :week="currentWeekNumber"
      :loading="tasksLoading"
      :group="group"
    />
  </div>
</template>

<script>
import TaskList from "../components/TaskList.vue";
import db from "@/firebase/init";
import { mapState } from "vuex";

export default {
  components: { TaskList },
  computed: mapState(["weeks", "group"]),
  methods: {
    changeWeek(number) {
      if (number - 1 == 0) {
        this.previousBtn = false;
      } else {
        this.previousBtn = true;
      }

      if (number + 1 > this.weeks.length) {
        this.nextBtn = false;
      } else {
        this.nextBtn = true;
      }

      this.currentWeekNumber = number;

      this.tasksLoading = true;

      let docRef = db
        .collection("weeks")
        .doc(this.weeks[this.currentWeekNumber - 1]);
      docRef
        .get()
        .then((doc) => {
          this.taskList = doc.data().taskList;
          this.tasksLoading = false;
        })
        .catch(() => {
          alert("Przepraszamy. Wystąpił błąd.");
        });
    },
  },
  mounted() {
    this.currentWeekNumber = this.weeks.length;

    if (this.currentWeekNumber == 0) {
      this.$router.replace({ name: "Dashboard" });
    }

    if (this.currentWeekNumber == 1) {
      this.previousBtn = false;
    }

    let docRef = db
      .collection("weeks")
      .doc(this.weeks[this.currentWeekNumber - 1]);
    docRef
      .get()
      .then((doc) => {
        this.taskList = doc.data().taskList;
        this.tasksLoading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  data() {
    return {
      currentWeekNumber: 0,
      taskList: [],
      tasksLoading: true,
      nextBtn: false,
      previousBtn: true,
    };
  },
};
</script>

<style></style>
